import { v4 as uuidv4 } from 'uuid'

export default {
	async getAll() {
		return {
			data: [
				{
					id: uuidv4(),
					photo: 'https://static.thenounproject.com/png/112829-200.png',
					first_name: 'Erquies',
					last_name: 'Canoles',
					full_name: 'Erquies Canoles',
					email: 'javvier@gmail.com',
					nid: 123456789,
					profession: 'Doctor',
					area: 'Neonatal',
					request: 'Permiso',
				},
				{
					id: uuidv4(),
					photo: 'https://static.thenounproject.com/png/112829-200.png',
					first_name: 'Yiseth',
					last_name: 'Ruiz',
					full_name: 'Yiseth Ruiz',
					email: 'yisethr@gmail.com',
					nid: 123456789,
					profession: 'Enfermera',
					area: 'Hospitalización',
					request: 'Cambio de horario',
				},
				{
					id: uuidv4(),
					photo: 'https://static.thenounproject.com/png/112829-200.png',
					first_name: 'Tomas',
					last_name: 'Herrera',
					full_name: 'Tomas Herrera',
					email: 'tomash@gmail.com',
					nid: 123456789,
					profession: 'Doctor',
					area: 'UCI_AD Cirugía',
					request: 'Cambio de horario',
				},
				{
					id: uuidv4(),
					photo: 'https://static.thenounproject.com/png/112829-200.png',
					first_name: 'Eider',
					last_name: 'Silva',
					full_name: 'Eider Silva',
					email: 'eiders@gmail.com',
					nid: 123456789,
					profession: 'Enfermero',
					area: 'Urgencias',
					request: 'Cambio de horario',
				},
				{
					id: uuidv4(),
					photo: 'https://static.thenounproject.com/png/112829-200.png',
					first_name: 'Juan C.',
					last_name: 'Rocha',
					full_name: 'Juan C. Rocha',
					email: 'juancr@gmail.com',
					nid: 123456789,
					profession: 'Doctor',
					area: 'Hospitalización',
					request: 'Permiso',
				},
				{
					id: uuidv4(),
					photo: 'https://static.thenounproject.com/png/112829-200.png',
					first_name: 'Frank',
					last_name: 'Silva',
					full_name: 'Franck Silva',
					email: 'franks@gmail.com',
					nid: 123456789,
					profession: 'Doctor',
					area: 'Mantenimiento Serv. Generales',
					request: 'Cambio de horario',
				},
			],
		}
	},
}
